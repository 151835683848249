.input-finder-search-bar {
  margin-right: 10px;
  width: 100%;
}

.img-advanced-filter {
  display: block;
  margin-top: 3px;
  margin-left: 3px;
  height: 30px;
  width: 30px;
}

.custom-searchbar {
  padding: 0;
  border-top: 0.1px solid grey;
  border-left: 0.1px solid grey;
  border-right: 0.1px solid grey;
  border-bottom: 0.1px solid grey;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  width: 100%;
}

.custom-searchbar-input {
  border: none;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-searchbar-input:focus {
  outline: none !important;
  outline-style: none !important;
  outline-color: rgba(0, 0, 0, 0);
}

.search-icon {
  margin-left: 10px;
}
.simple-filter {
}

.advanced-filter-credentials {
  margin-top: 0px;
  position: absolute;
  min-height: 400px;
  background-color: white;
  z-index: 20;
  width: 72%;
}

.black-layout {
  position: absolute;
  height: 100vw;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 15;
}
