.input-finder-advanced-search-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 5%;
    width: 90%;
}

.buttonCancel {
    margin-left: 79%;
    margin-right: 1%;
    border: none;
}

.buttonFilter {
    background-color: #D0A650;
    border: none;
    margin-bottom: 2px;
}
