.blue-container {
  background-color: #125FDE;
  font: normal normal medium 18px/47px TT-Norms;
  letter-spacing: 0px;
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blue-container img {
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .blue-container img {
    width: 100%;
  }
}
