.home-container {
  display: flex;
  padding: 25px 0;
  height: 80vh;
}

.refresh-icon-container {
  display: flex;
  width: 100%;
  margin-top: -20px;
  position: relative;
  top: 40px;
  right: 15px;
  z-index: 11;
}

.refresh-button {
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.info-column,
.background-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
}

.info-column {
  width: 40%;
}

.background-column {
  width: 60%;
}

.background-column img {
  width: 100%;
  display: block;
  margin: auto;
  max-height: 500px;
}

.vid-chain-buttons-container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 37px;
}

.vid-chain-buttons-container .sign-in-vid-chain {
  width: 95%;
}

.vid-chain-buttons-container button,
.vid-chain-buttons-container a {
  height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border-radius: 2px;
}

.vid-chain-buttons-container .sign-in-vid-chain:nth-child(2) {
  background: #151a35;
  border: 1px solid #151a35;
  margin-bottom: 19px;
  font-weight: bold;
  color: white;
  padding: 7.5px 15.5px;
  width: 89%;
}

.vid-chain-buttons-container .sign-in-vid-chain:nth-child(2):hover {
  background: white;
  border: 2px solid #151a35;
  margin-bottom: 19px;
  font-weight: bold;
  color: #151a35;
}

.credentials-container {
  margin-top: -15%;
  width: calc(100% - 100px);
  margin-left: 100px;
  padding: 5px 20px;
}

.open-lateral-menu {
  width: 77.5%;
  margin-left: 22.5%;
}

.filter-list-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.buttonCreateUser,
.buttonCreateUser:focus {
  background: #D0A65033 0% 0% no-repeat padding-box;
  border: 2px solid #D0A650;
  border-radius: 31px;
  opacity: 1;
  font-family: 'TT-Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  outline: none;
  margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .credentials-container {
    margin-left: 0;
    width: 100%;
  }
  .open-lateral-menu {
    width: 100%;
    margin-left: 0%;
  }
}

@media (max-width: 600px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 150px;
  }

  .info-column,
  .background-column {
    width: 100%;
  }
}
