.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    height: 80vh;
  }

  .info-column, .background-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
  }

  .info-column {
    width: 40%;
  }

  .background-column {
    width: 60%;
  }

  .background-column img {
    display: block;
    margin: auto;
  }

  .success-image, .error-image {
    width: 80%!important;
  }


  .vid-chain-buttons-container {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 37px;
  }

  .vid-chain-buttons-container .sign-in-vid-chain {
    width: 95%;
  }

  .vid-chain-buttons-container button, .vid-chain-buttons-container a  {
    height: 51px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    border-radius: 2px;
  }

.vid-chain-buttons-container .sign-in-vid-chain:nth-child(1) {
  background: white;
  margin-bottom: 19px;
  border: 2px solid #D0A650;
  font-weight: bold;
  color: #D0A650;
  padding: 7.5px 15.5px;
}

.vid-chain-buttons-container .sign-in-vid-chain:hover {
  border: 1px solid #D0A650;
  background-color: #D0A650;
  color: white;
}

  .vid-chain-buttons-container .sign-in-vid-chain:nth-child(2) {
    background: #151A35;
    border: 1px solid #151A35;
    margin-bottom: 19px;
    font-weight: bold;
    color: white;
    padding: 7.5px 15.5px;
    width: 89%;
  }

  .vid-chain-buttons-container .sign-in-vid-chain:nth-child(2):hover {
    background: white;
    border: 2px solid #151A35;
    margin-bottom: 19px;
    font-weight: bold;
    color: #151A35;
  }

  @media (max-width: 600px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 150px;
  }

  .info-column, .background-column {
    width: 100%;
  }

  }
