.iconAvatar {
  width: 32px;
  height: 32px;
  filter: hue-rotate(90deg) contrast(1.7);
}
.content_right {
  float: right;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 15px;
}

.content_right h6 {
  margin-bottom: 0;
}

.subtitleAvatar {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 0;
}
